/*@import "~bootstrap-icons/font/bootstrap-icons.css";*/


/*
utilizar o materialpaletter para montar as cores dos parceiros
https://www.materialpalette.com/
*/

:root {
    --main-bt-collor-strong: #7b7b7b;
    --main-bt-collor-weak: #9e9e9e;
    --main-background: white;
    --color-button-overall: #323232;
    --primary-color: #820AD1;
    --alert-color: #E7DBF1;
    --stepper-disable: #E7DBF1;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Roboto';
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none!important;
}

.loading-text {
    color: var(--main-bt-collor-strong);
}

.position-relative {
    position: relative;
}

.background {
    height: 100vh;
    background-color: var(--main-background);
}

.company-logo {
    height: 45px;
}

.width-100 {
    width: 100%!important;
}

.layout-header {
    height: 50px;
    background-color: var(--main-background);
    width: 100%;
}

.container {
    padding: 0px 32px 0px 32px;
}

.container-input {
    width: 100%;
    border-bottom: 1px solid #f6f5f8;
    margin-top: 30px;
}

.container-input-password {
    height: 30px;
    width: 100%;
    border-bottom: 1px solid #f8f5f5;
    display: inline-flex;
}

.layout-input {
    height: 100%;
    width: 100%;
    font-size: 14px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #acacac;
}

.layout-input:hover {
    border: none;
    box-shadow: none;
    outline: none;
}

.layout-input:focus-visible {
    border: none;
    box-shadow: none;
    outline: none;
}

.label-form-login {
    font-size: 12px;
    color: #acacac;
}

.layout-btn {
    margin-top: 50px;
    background-image: linear-gradient(to right, var(--main-bt-collor-strong), var(--main-bt-collor-weak));
    height: 50px;
    border-radius: 25px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.layout-btn-text {
    font-size: 14px;
    font-weight: 700;
    color: white;
}

.back-icon {
    width: 17px;
    margin-top: 15px;
    margin-left: 15px;
}

.password-eye {
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
}

.layout-container-input {
    width: 100%;
    border-bottom: 1px solid #f6f5f8;
    margin-top: 10px;
}

.layout-input-overall {
    height: 30px;
    width: 100%;
    font-size: 14px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #acacac;
}

.layout-label-overall {
    width: 100%;
    font-size: 12px;
    color: #c1c1c1;
}

.layout-btn-overall {
    margin-top: 20px;
    background-color: var(--color-button-overall);
    height: 50px;
    border-radius: 25px;
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.layout-btn-label-overall {
    color: white;
    font-weight: 500;
    font-size: 14px;
}

.container-erro-text-message {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
}

.erro-text-message {
    color: #80380A;
    font-weight: 700;
    font-size: 14px;
}

.alert-text-message {
  color: #80380A;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}

.display-inline-flex-center {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-dialog-container {
    border-radius: 14px!important;
    opacity: 0.9;
    border-bottom: 1px solid #000;
    padding-left: 0!important;
    padding-right: 0!important;
}
